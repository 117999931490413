import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {BehaviorSubject, zip} from 'rxjs';
import { map } from 'rxjs/operators';
import { DialogService } from 'services/prozesse/dialog.service';
import { MandantenService } from 'services/mandanten/mandanten.service';
import { SpeiseplanService } from 'submodul/obs.speiseplan/services/speiseplan.service';
import { MitteilungsService } from 'services/prozesse/mitteilung.service';
import { MatDialog } from '@angular/material/dialog';
import {SeitenleisteService} from '../../../submodul/obs.seitenleiste/seitenleiste.service';
import {SeitenleisteInhalt} from '../../../submodul/obs.seitenleiste/seitenleisteInhalt.class';
import {SpeiseplanDetailsComponent} from '../../../submodul/obs.speiseplan/einzelbesteller/speiseplanDetails/speiseplanDetails.component';

@Component({
	selector: 'web-speiseplan-mandant',
	templateUrl: './web-speiseplan-mandant.component.html',
	styleUrls: ['../../../submodul/obs.speiseplan/einzelbesteller/speiseplanDarstellung/speiseplanDarstellungWBP.css'],
})

export class WebSpeiseplanMandantComponent implements OnInit {

	@ViewChild('speiseplan_kalender') speiseplan_kalender
	@Output() ItemEvent = new EventEmitter()
	public speiseplan_id
	public mandant = null
	public kunde = null
	public speiseplan: any
	public modus
	public api_zeitraum
	public zeitraum
  public pdf_plaene
	public path
  public date
  public einrichtungsinfo = ''
  public menuDetailsObserver: BehaviorSubject<boolean | object> = new BehaviorSubject(false)
  public aktuellerTag
  public menuId
  public darstellung
  public speiseplanLeer

	constructor(
		private dialog_service: DialogService,
		private active_route: ActivatedRoute,
		private mandant_service: MandantenService,
		private speiseplan_service: SpeiseplanService,
		private message_service: MitteilungsService,
    private seitenleistenService: SeitenleisteService,
		private router: Router,
		public dialog: MatDialog,
    public pdf: MatDialog
	) {
	}

	ngOnInit() {
		this.dialog_service.app_menu = true
		this.path = this.router.url
		this.dialog_service.activePage_get(this.path)
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    this.date = yyyy + '-' + mm + '-' + dd;

    this.active_route.params.subscribe((params) => {
      this.speiseplan_id = params['id']
      this.api_zeitraum = null
      this.mandant = null
      this.speiseplan = null
      zip(
        this.speiseplan_service.speiseplan_zeitraum(),
        this.mandant_service.mandant_get()
      ).subscribe(apiResponse => {
        this.api_zeitraum = apiResponse[0]
        this.mandant = apiResponse[1]
        let speiseplan = Object.values(apiResponse[1].speisePlanIds).filter((speiseplan:any) => {
          return speiseplan.splanId === this.speiseplan_id
        })
        this.darstellung = window.screen.availWidth >= 1500 ? speiseplan[0]['darstellung'] : 2
      })
    })
	}

	speiseplan_laden(zeitraum) {
    if (zeitraum.length != 0){
      this.zeitraum = zeitraum
      this.modus = this.speiseplan_kalender.modus
      this.speiseplan_service.speiseplan_mandant(this.speiseplan_id, zeitraum.start, zeitraum.ende).pipe(
        map((response) => { return <any>response })
      ).subscribe((response) => {
        this.pdf_plaene = response.splanPdfs
        let key: any
        let value: any
          for ([key, value] of Object.entries(this.mandant.speisePlanIds)){
            this.speiseplan = {
              mandant: this.mandant,
              bestellschluss: response.bestellschlussMsgs,
              menu: response.speiseplanTage,
              config: Object.values(this.mandant.speisePlanIds).filter((speiseplan: any) => { if (speiseplan.splanId === this.speiseplan_id) { return speiseplan } })
            }
            let menu:any
            for (menu of Object.entries(this.speiseplan.menu)){
              if (this.date == menu[0]){
                this.menu_details_aktualisieren(Object.entries(menu[1].tagesMenues)[0][1], this.date)
              } else {
                let date = new Date(zeitraum.start),
                  new_date = new Date(date.setDate(date.getDate() + 0.5)),
                  new_date_dd = '' + new_date.getDate(),
                  new_date_mm = '' + (new_date.getMonth() + 1),
                  new_date_yyyy = new_date.getFullYear()
                if (new_date_mm.length < 2){
                  new_date_mm = '0' + new_date_mm;
                }
                if (new_date_dd.length == 1){
                  new_date_dd = '0' + new_date_dd;
                }
                if (menu[0] == (new_date_yyyy + '-' + new_date_mm + '-' + new_date_dd)){
                  this.menu_details_aktualisieren(Object.entries(menu[1].tagesMenues)[0][1], this.date)
                }
              }
            }
          }
        },(error) => {
          this.message_service.setMitteilung('8802')
        }, (
          () => {})
        )
    } else {
      this.message_service.setMitteilung('8202')
    }
	}

	menu_details_aktualisieren(menu, tag) {
    menu['bezeichnung'] = menu.bezeichnung
    menu['menueText'] = menu.menueText
    menu['naehrwerte'] = menu.naehrwerte
    menu['allergene'] = menu.allergeneIds
    menu['inhaltsstoffe'] = menu.inhaltsstoffeIds
    menu['zusatzstoffe'] = menu.zusatzstoffeIds
    this.menuId = menu.menueNr
    this.aktuellerTag = tag.length > 0 ? this.date : tag.key
    this.menuDetailsObserver.next(menu)
    this.seitenleistenService.updateSeitenleiste(new SeitenleisteInhalt(
      SpeiseplanDetailsComponent, {
        mandant: this.mandant,
        // menuTag: tag.length > 0 ? this.date : tag.key,
        speiseplanDetailsObserver: this.menuDetailsObserver
      })
    )
	}

  protected readonly Object = Object;
}

