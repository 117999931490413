<div id="speiseplanKunde" *ngIf="mandant && kunde">
  <mat-card id="speiseplan-kunde-header">
    <speiseplan-kalender
      #speiseplan_kalender
      [mandant]="mandant"
      [kunde]="kunde"
      [apiZeitraum]="apiZeitraum"
      (zeitraum_update)="speiseplan_laden($event)"
    ></speiseplan-kalender>
    <web-speiseplan-header
      *ngIf="speiseplan"
      [kunde]="kunde"
      [mandant]="mandant"
      [zeitraum]="zeitraum"
      [modus]="modus"
      [speiseplan]="speiseplan"
      [einrichtungsinfo]="einrichtungsinfo"
      [pdf_plaene]="pdf_plaene"
    ></web-speiseplan-header>
  </mat-card>
  <div id="speiseplan-kunde" *ngIf="speiseplan"  [class.speiseplanLeer]="Object.keys(speiseplan.menu).length == 0">
    <div speiseplan-darstellung [speiseplan]="speiseplan" [darstellung]="darstellung" [modus]="modus" class="speiseplan-kunde-grid" [tag]="speiseplan.menu | keyvalue">
      <div *ngFor="let tag of speiseplan.menu | keyvalue" class="speiseplan-tag">
        <speiseplanTagLabel class="splanLabel" [speiseplan]="speiseplan" [tag]="tag" [darstellung]="darstellung"></speiseplanTagLabel>
        <speiseplanTagContent
          speiseplanTag
          [speiseplan_tag]="tag"
          [darstellung]="darstellung"
          [modus]="modus"
          [mandant]="mandant"
          [kunde]="kunde"
          [tag]="tag"
          [speiseplan]="speiseplan"
          [config]="config"
        ></speiseplanTagContent>
      </div>
    </div>
  </div>
</div>
